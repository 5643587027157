.app-container {
  background-color: #f8f8f8;
}

.app-main-about-me {
  height: 65vh;
  width: 100%;
}

.app-main {
  height: 100vh;
}

.app-main-footer {
  min-height: 45vh
}

.values-container {
  /* max-height: 100vh; */
  padding: 100px 0px;
}

.image-container {
  position: relative;
  height: 100vh;
  width: 100%;
}

.image-wrapper {
  position: relative;
  height: auto;
  max-height: 100%;
  width: 100%;
  object-fit: cover;
}

.app-main-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3.5rem;
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
}

.app-about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.app-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
}

.app-header-text {
  font-size: 3rem;
  color: #fff;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.app-subheader-text {
  font-size: 1rem;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.values-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
}

.values-header-text {
  font-size: 1.25rem;
  color: rgb(21, 21, 21);
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  padding-left: 10px;
}

.values-body-text {
  font-size: 1rem;
  color: rgb(21, 21, 21);
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 30px;
}

.app-contact-header {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
}

.app-subcontact-header {
  font-size: 1.25rem;
  color: #000;
  font-family: 'Open Sans', sans-serif;
}

@media (max-width: 850px) {
  .image-container {
    height: 100%;
  }
  .values-container {
    padding-top: 0px;
    /* min-height: 60vh; */
    padding-bottom: 100px;
  }

  .app-main-about-me {
    min-height: 100vh;
    width: 100%;
  }


.app-main-container {
  font-size: 2.5rem;
  /* top: -50px; */
  padding: 0px 20px;
}
}